import { OneUxElement } from '../../OneUxElement.js'
import { html, nothing } from 'lit'
import { customElement, property, query, queryAssignedElements, state } from 'lit/decorators.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { style } from './style.js'
import { Weight } from '../../mixins/Weight.js'
import { Purpose } from '../../mixins/Purpose.js'
import { Implicit } from '../../mixins/Implicit.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { Focusable } from '../../mixins/Focusable.js'
import { Disabled } from '../../mixins/Disabled.js'
import { Label } from '../../mixins/Label.js'
import { Busy } from '../../mixins/Busy.js'
import { Optional } from '../../types.js'
import { HidableTooltip } from '../../mixins/HidableTooltip.js'
import { classMap } from 'lit/directives/class-map.js'
import { keyCodes } from '../../utils.js'
import type { OneUxPopoutElement } from '../../elements/one-ux-popout/OneUxPopoutElement.js'
import { provide } from '@lit/context'
import { IPopoutContext, popoutContext } from '../../contexts/PopoutContext.js'
import { SlotController } from '../../controllers/SlotController.js'
import { styleMap } from 'lit/directives/style-map.js'
import { TABBABLE_TARGETS_SELECTOR } from '../../utils/focusable.js'

const Styled = StyledFactory(style)

const BaseClass = Busy(HidableTooltip(Label(Disabled(Focusable(Implicit(Purpose(Weight(Styled(OneUxElement)))))))))

@customElement('one-ux-button')
export class OneUxButtonElement extends BaseClass {
  static formAssociated = true

  @provide({ context: popoutContext })
  private _popoutContext = {
    closePopout: () => {
      if (this._active) {
        this._buttonElement.focus()
        this.#toggleActive()
      }
    }
  } as IPopoutContext

  #elementInternals!: ElementInternals

  constructor() {
    super()
    this.#elementInternals = this.attachInternals()

    this.addEventListener('blur', this.#handleBlur, { capture: true })
    this.addEventListener('keydown', this.#handleKeydown, { capture: true })
  }

  @property({ attribute: 'form-action', type: String })
  public accessor formAction: Optional<'submit' | 'reset'>

  @state()
  private accessor _active = false

  #slots: SlotController = new SlotController(this, {
    defaultSlot: true,
    slots: ['start', 'end', 'popout', 'adornment']
  })

  protected guardedRender() {
    const isCompact = this.#isCompact
    const compactLabel = isCompact ? this.label : undefined
    const tooltip = this.hideTooltip ? undefined : compactLabel

    const content = this.#isDefaultMenuButton
      ? html`<one-ux-icon set="internal" icon="context-menu"></one-ux-icon>`
      : html`<span class="button-text">${this.label}</span>`

    return html`<button
        id="button"
        @click=${this.#handleClick}
        class=${classMap({
          'one-ux-element--root': true,
          compact: isCompact
        })}
        ?disabled=${this.disabled}
        tabindex=${
          /* required to normalize focus behavior of buttons: https://bugs.webkit.org/show_bug.cgi?id=22261 */
          this.disabled ? -1 : 0
        }
        one-ux-tooltip=${ifDefined(tooltip)}
        ?one-ux-tooltip-custom-aria=${!!tooltip}
        ?one-ux-tooltip-fixed=${tooltip && isCompact}
        aria-label=${ifDefined(compactLabel)}
        aria-haspopup=${ifDefined(this.#hasPopoutContent || undefined)}
        aria-expanded=${ifDefined(this.#hasPopoutContent ? this._active : undefined)}
        aria-pressed=${ifDefined(this.#hasPopoutContent ? this._active : undefined)}
        aria-disabled=${ifDefined(this.busy ? true : undefined)}
        type="button"
      >
        <slot name="start"></slot>
        <slot></slot>
        ${this.#hasDefaultContent ? nothing : content}
        <slot name="end"></slot>
        <div
          class=${classMap({
            adornment: true,
            'is-empty': !this.#slots.hasNamedSlot('adornment')
          })}
        >
          <slot name="adornment"></slot>
        </div>
        ${this.busy
          ? // Cannot clip directly with button as that will clip adornments
            html`<div class="spinner-clip">
              <div
                class=${classMap({
                  spinner: true,
                  round: isCompact && this.weight === 'high'
                })}
              ></div>
            </div>`
          : nothing}
      </button>
      ${this.#hasPopoutContent && this._active
        ? html`<one-ux-popout
            class="popout"
            reference="previous"
            indent="none"
            style=${styleMap({
              minWidth: `${(this.shadowRoot!.querySelector('#button')! as HTMLButtonElement).offsetWidth}px`
            })}
          >
            <one-ux-scroll
              style="width: 100%; max-height: var(--one-ux-button-element--popout-max-height, max(35vh, 250px))"
            >
              <slot name="popout"></slot>
            </one-ux-scroll>
          </one-ux-popout>`
        : html`<slot hidden name="popout"></slot>`}`
  }

  #handleClick = (originalEvent: MouseEvent) => {
    originalEvent.stopPropagation()
    if (this.busy) return

    const event = new MouseEvent('click', originalEvent)
    if (this.dispatchEvent(event)) {
      if (this.#hasPopoutContent) {
        this.#toggleActive()
      } else {
        switch (this.formAction) {
          case 'submit': {
            this.#elementInternals.form?.requestSubmit()
            break
          }
          case 'reset': {
            this.#elementInternals.form?.reset()
            break
          }
        }
      }
    }
  }

  @queryAssignedElements({ slot: 'popout' })
  private accessor _popoutContent!: Array<HTMLElement>

  @query('#button')
  private accessor _buttonElement!: OneUxPopoutElement

  #getFirstFocusableInPopout() {
    const content = this._popoutContent
    for (const $element of content) {
      if ($element.matches(TABBABLE_TARGETS_SELECTOR)) {
        return $element
      }
      const $focusableChild = $element.querySelector<HTMLElement | SVGElement>(TABBABLE_TARGETS_SELECTOR)
      if ($focusableChild) {
        return $focusableChild
      }
    }
  }

  #toggleActive = async () => {
    if (this._active) {
      this._active = false
      await this.updateComplete
      this.dispatchEvent(new Event('close'))
    } else {
      this._active = true
      await this.updateComplete
      const $child = this.#getFirstFocusableInPopout()
      if ($child) {
        const observer = new IntersectionObserver(async () => {
          if ('updateComplete' in $child) {
            await $child.updateComplete
          }
          $child.focus()
          this.dispatchEvent(new Event('open'))
          observer.disconnect()
        })
        observer.observe($child)
      }
    }
  }

  #handleBlur = (event: FocusEvent) => {
    if (this._active) {
      const $newFocus = event.relatedTarget as HTMLElement
      if (this.contains($newFocus) || this.shadowRoot?.contains($newFocus)) {
        return
      }
      this.#toggleActive()
    }
  }

  #handleKeydown = (event: KeyboardEvent) => {
    if (!this.#hasPopoutContent) {
      return
    }
    const handled = () => {
      event.stopPropagation()
      event.preventDefault()
    }

    if (!this._active) {
      switch (event.code) {
        case keyCodes.SPACE:
        case keyCodes.UP:
        case keyCodes.DOWN:
        case keyCodes.RETURN:
          if (this._active) {
            this._buttonElement.focus()
          }
          this.#toggleActive()
          return handled()
      }
    } else {
      switch (event.code) {
        case keyCodes.ESCAPE:
          this._buttonElement.focus()
          this.#toggleActive()
          return handled()
      }
    }
  }

  get #hasPopoutContent() {
    return this.#slots.hasNamedSlot('popout')
  }

  get #hasDefaultContent() {
    return this.#slots.hasDefaultSlot()
  }

  get #isDefaultMenuButton() {
    return this.#hasPopoutContent && !this.#slots.hasNamedSlot('start') && !this.#slots.hasNamedSlot('end')
  }

  get #isCompact() {
    if (
      this.#slots.hasDefaultSlotTextContent() ||
      this.#slots.hasNamedSlot('start') ||
      this.#slots.hasNamedSlot('end')
    ) {
      return false
    }
    if (!this.#slots.hasSingleDefaultSlot('one-ux-icon') && !this.#slots.hasNamedSlot('popout')) {
      return false
    }
    return true
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-button': OneUxButtonElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-button': OneUxButtonElement
    }
  }
}
