import { html } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { Focusable } from '../../mixins/Focusable.js'
import { Implicit } from '../../mixins/Implicit.js'
import { Compact } from '../../mixins/Compact.js'
import { Purpose } from '../../mixins/Purpose.js'
import { OneUxElement } from '../../OneUxElement.js'
import { IValue, ValueFactory } from '../../mixins/Value.js'
import { ValidatedFactory, getFormValidationLanguage, validResult } from '../../mixins/Validated.js'
import { InternalValueChangedEvent } from '../../events/internal/InternalValueChangedEvent.js'
import { FormAssociated } from '../../mixins/FormAssociated.js'
import { Required, type IRequired } from '../../mixins/Required.js'
import { Label } from '../../mixins/Label.js'
import { FieldSetProps } from '../../elements/one-ux-field-set/FieldSetProps.js'
import { Errors } from '../../mixins/Errors.js'
import { Disabled } from '../../mixins/Disabled.js'

type CheckboxOption = {
  value: unknown
  text: string
  disabled: boolean
}

type valueType = unknown[]
const Value = ValueFactory<valueType>({
  type: Array,
  reflect: true
})

const Validated = ValidatedFactory<IValue<valueType> & IRequired>({
  validator() {
    if (!this.required) {
      return validResult
    }

    const { fieldYouHaveToMakeChoice } = getFormValidationLanguage(this)
    const hasValue = !!this.value?.length

    return {
      valid: hasValue,
      flags: {
        valueMissing: !hasValue
      },
      errors: [fieldYouHaveToMakeChoice]
    }
  }
})

const BaseClass = Errors(
  Label(
    Disabled(
      Required(FieldSetProps(FormAssociated(Validated(Value(Focusable(Compact(Implicit(Purpose(OneUxElement)))))))))
    )
  )
)

/**
 * A group of check boxes, where several can be checked.
 */
@customElement('one-ux-checkbox-group')
export class OneUxCheckboxGroupElement extends BaseClass {
  constructor() {
    super()

    this.value = []
  }
  /*
   * The list of options for the checkbox group.
   * * text: Displayed text for option
   * * value: When listening on the option event it will be passed as argument
   * * disabled: If the option is disabled
   */
  @property({ type: Array })
  public accessor options: CheckboxOption[] = []

  /*
   * Placement of the checkboxes against their texts.
   */
  @property({ attribute: 'checkbox-position', type: String })
  public accessor checkboxPosition = 'before-text' as 'before-text' | 'after-text'

  render() {
    return html`
      <one-ux-field-set
        class="one-ux-element--root"
        .delegateAria=${{
          role: 'listbox',
          'aria-orientation': 'vertical',
          'aria-multiselectable': true
        }}
        .disabled=${this.disabled}
        .implicit=${this.implicit}
        .compact=${this.compact}
        .label=${this.label}
        .columns=${this.columns}
        .errors=${this.errors}
        .required=${this.required}
      >
        ${this.options.map(
          ({ disabled, value, text }, index) => html`
            <one-ux-label label=${text}>
              <one-ux-checkbox
                tabindex=${index == 0 && !this.hasKeyboardFocus ? 0 : -1}
                slot=${this.checkboxPosition === 'before-text' ? 'start' : 'end'}
                .disabled=${this.disabled || disabled}
                .checked=${Array.isArray(this.value) ? this.value.includes(value) : false}
                .delegateAria=${{ role: 'option' }}
                .implicit=${this.implicit}
                .purpose=${this.purpose}
                pdr-test-hook="one-ux-checkbox-group-item-${String(value)}"
                @change=${() => this.#handleSelect(value)}
              >
              </one-ux-checkbox>
            </one-ux-label>
          `
        )}
      </one-ux-field-set>
    `
  }

  #select(value: unknown) {
    if (this.value?.includes(value)) {
      this._applyUserValue(this.value.filter((v: unknown) => v !== value))
    } else {
      this._applyUserValue([value, ...(this.value || [])])
    }
  }

  #handleSelect(value: unknown) {
    this.#select(value)
    this.dispatchEvent(new Event('input'))
    this.dispatchEvent(new Event('change'))
    this.dispatchEvent(new InternalValueChangedEvent())
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-checkbox-group': OneUxCheckboxGroupElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-checkbox-group': OneUxCheckboxGroupElement
    }
  }
}
