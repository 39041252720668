import { html } from 'lit'
import { customElement } from 'lit/decorators.js'
import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { Errors } from '../../mixins/Errors.js'
import { classMap } from 'lit/directives/class-map.js'
import { Empty } from '../../mixins/Empty.js'
import { FocusableFactory } from '../../mixins/Focusable.js'
import { ErrorsPopout } from '../../fragments/ErrorsPopout.js'

const Styled = StyledFactory(style)
const Focusable = FocusableFactory(false)
const BaseClass = Focusable(Empty(Errors(Styled(OneUxElement))))

/**
 * Defines content that the user can interact with.
 */
@customElement('one-ux-interactive-content')
export class OneUxInteractiveContentElement extends BaseClass {
  render() {
    return html`<div
      class=${classMap({
        'one-ux-element--root': true,
        'has-errors': this.errors.length,
        empty: this.empty
      })}
    >
      <slot></slot>
      <div class="indicator"></div>
      ${ErrorsPopout({
        reference: 'parent',
        errors: this.errors,
        hidden: this.hideErrors
      })}
    </div>`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-interactive-content': OneUxInteractiveContentElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-interactive-content': OneUxInteractiveContentElement
    }
  }
}
