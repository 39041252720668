import { css } from 'lit'

export const style = css`
  .one-ux-element--root {
    --one-ux-collapsible-section--hover: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
    --one-ux-collapsible-section--pressed: rgba(var(--one-ux-rgb--brand-neutral-600), 0.15);
    --one-ux-collapsible-section--expanded: rgba(var(--one-ux-rgb--brand-neutral-600), 0.12);
    --one-ux-collapsible-section--expanded-hover: rgba(var(--one-ux-rgb--brand-neutral-600), 0.15);
    --one-ux-collapsible-section--expanded-pressed: rgba(var(--one-ux-rgb--brand-neutral-600), 0.21);
    --one-ux-collapsible-section--keyboard-focus: var(--one-ux-palette--keyboard-focus-300);
    --one-ux-collapsible-section--keyboard-focus-pressed: var(--one-ux-palette--keyboard-focus-400);

    --one-ux-collapsible-section--button-background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.06);
    --one-ux-collapsible-section--outline-color: transparent;
    --one-ux-collapsible-section--border-radius: var(--one-ux-radius--300);
  }

  /* EXPLICIT */
  [role='heading'] {
    --one-ux-collapsible-section--button-background-color: rgba(var(--one-ux-rgb--brand-neutral-600), 0.06);

    &:hover {
      --one-ux-collapsible-section--button-background-color: var(--one-ux-collapsible-section--hover);
    }
    &:active {
      --one-ux-collapsible-section--button-background-color: var(--one-ux-collapsible-section--pressed);
    }
    :host([open]) & {
      --one-ux-collapsible-section--button-background-color: var(--one-ux-collapsible-section--expanded);
    }
    :host([open]) &:hover {
      --one-ux-collapsible-section--button-background-color: var(--one-ux-collapsible-section--expanded-hover);
    }
    :host([open]) &:active {
      --one-ux-collapsible-section--button-background-color: var(--one-ux-collapsible-section--expanded-pressed);
    }
  }

  /* IMPLICIT */
  :host([implicit]) .one-ux-element--root {
    --one-ux-collapsible-section--expanded-hover: rgba(var(--one-ux-rgb--brand-neutral-600), 0.18);
    --one-ux-collapsible-section--expanded-pressed: rgba(var(--one-ux-rgb--brand-neutral-600), 0.24);
    --one-ux-collapsible-section--outline-color: var(--one-ux-collapsible-section--hover);

    & [role='heading'] {
      --one-ux-collapsible-section--button-background-color: none !important;
    }

    &:hover {
      --one-ux-collapsible-section--outline-color: var(--one-ux-collapsible-section--expanded);
    }
    &:active {
      --one-ux-collapsible-section--outline-color: var(--one-ux-collapsible-section--pressed);
    }
    :host([open]) & {
      --one-ux-collapsible-section--outline-color: var(--one-ux-collapsible-section--expanded);
    }
    :host([open]) &:hover {
      --one-ux-collapsible-section--outline-color: var(--one-ux-collapsible-section--expanded-hover);
    }
    :host([open]) &:active {
      --one-ux-collapsible-section--outline-color: var(--one-ux-collapsible-section--expanded-pressed);
    }
  }

  /* FOCUS */
  :host([state-keyboard-focus]) [role='heading'] {
    &:has(button:focus) {
      --one-ux-collapsible-section--button-background-color: var(
        --one-ux-collapsible-section--keyboard-focus
      ) !important;
    }
    &:has(button:focus:active) {
      --one-ux-collapsible-section--button-background-color: var(
        --one-ux-collapsible-section--keyboard-focus-pressed
      ) !important;
    }
  }

  /* STYLE */
  .one-ux-element--root {
    outline-style: solid;
    outline-offset: calc(var(--one-ux-spacing--100) * -1);
    outline-width: var(--one-ux-spacing--100);
    outline-color: var(--one-ux-collapsible-section--outline-color);
    border-radius: var(--one-ux-collapsible-section--border-radius);
    margin-bottom: var(--one-ux-spacing--300);
  }

  [role='heading'] {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 40px;
    background: var(--one-ux-collapsible-section--button-background-color);
    border-radius: var(--one-ux-collapsible-section--border-radius);
    gap: var(--one-ux-spacing--300);
    padding: 0 var(--one-ux-spacing--400);
    user-select: none;

    font: var(--one-ux-font--heading-200);
    color: var(--one-ux-palette--brand-neutral-600);
    letter-spacing: var(--one-ux-letter-spacing--heading-200);

    transition-property: background, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  button {
    all: unset;
    -webkit-appearance: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    overflow: hidden;
    outline: none;
  }

  .header-content {
    display: flex;
    flex: 1 1 auto;
    min-width: 0;
    margin-left: var(--one-ux-spacing--200);
    gap: var(--one-ux-spacing--200);
    align-items: center;
  }

  .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-self: stretch;
  }

  .indicator {
    transition-property: rotate;
    transition-duration: var(--one-ux-duration--400);
    transition-timing-function: var(--one-ux-easing--default);

    &.expanded {
      rotate: -180deg;
    }
  }

  slot {
    &::slotted(one-ux-icon) {
      font-size: var(--one-ux-size--icon-300);
    }

    &:is([name='indicator-start'], [name='indicator'], [name='indicator-end'])::slotted(*) {
      align-self: center;
    }
  }

  .content-wrapper {
    overflow: hidden;
    visibility: hidden;
    height: 0;

    &.expanded {
      height: auto;
      visibility: visible;
    }
  }

  .content {
    width: auto;
    border-radius: var(--one-ux-collapsible-section--border-radius);

    :host(:not([disable-indent])) &.has-content {
      padding: var(--one-ux-spacing--300) var(--one-ux-spacing--400) var(--one-ux-spacing--500)
        var(--one-ux-spacing--400);
    }
  }
`
