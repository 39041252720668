import { html, nothing } from 'lit'
import { customElement, property } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'

import { StyledFactory } from '../../mixins/Styled.js'
import { OneUxElement } from '../../OneUxElement.js'
import { style } from './style.js'
import { Weight } from '../../mixins/Weight.js'
import { Optional } from '../../types.js'

const Styled = StyledFactory(style)

const BaseClass = Weight(Styled(OneUxElement))

const NUMBER_CAP_VALUE = 999

@customElement('one-ux-pill')
export class OneUxPillElement extends BaseClass {
  @property({ type: Number })
  accessor number: Optional<number>

  render() {
    return html`<div
      class=${classMap({
        'one-ux-element--root': true,
        'is-empty': !this.#hasValidNumber,
        'is-single-digit': this.#hasValidNumber && this.number! < 10
      })}
    >
      <span aria-hidden="true">${this.#hasValidNumber ? this.#outputNumber : nothing}</span>
      <span class="one-ux-accessibility--screen-reader">${this.#accessabilityOutput}</span>
    </div>`
  }

  get #hasValidNumber() {
    return this.number != null && this.number > 0
  }

  get #outputNumber() {
    return this.#hasValidNumber && this.number! > NUMBER_CAP_VALUE ? `${NUMBER_CAP_VALUE}+` : this.number!
  }

  get #accessabilityOutput() {
    return `(${this.#hasValidNumber ? this.number! : '*'})`
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-pill': OneUxPillElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-pill': OneUxPillElement
    }
  }
}
