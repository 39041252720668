import { html } from 'lit'
import { customElement, property, state } from 'lit/decorators.js'
import { classMap } from 'lit/directives/class-map.js'
import { styleMap } from 'lit/directives/style-map.js'
import { createRef, ref } from 'lit/directives/ref.js'
import { OneUxElement } from '../../OneUxElement.js'
import { FocusableFactory } from '../../mixins/Focusable.js'
import { Implicit } from '../../mixins/Implicit.js'
import { StyledFactory } from '../../mixins/Styled.js'
import type { OneUxIconToken, OneUxPaletteToken } from '../../generated/design-tokens.js'
import oneUxDuration from '../../generated/json/duration/duration.json'
import { closeAnimation, openAnimation } from './animations.js'
import { OpenClosedFactory } from '../../mixins/OpenClosed.js'

import { style } from './style.js'
import { log } from '../../utils/log.js'
import { flushAnimations } from '../../utils/animation-utils.js'
import { Optional } from '../../types.js'

type Icon = {
  set?: keyof OneUxIconToken
  name: string
  text: string
  color?: OneUxPaletteToken
}

const Styled = StyledFactory(style)
const Focusable = FocusableFactory(false)
const { Open } = OpenClosedFactory({
  type: 'open',
  async action() {
    const $wrapper = this.shadowRoot?.querySelector('.content-wrapper') as HTMLDivElement
    const $content = this.shadowRoot?.querySelector('.content') as HTMLDivElement
    if ($content && $wrapper) {
      flushAnimations($wrapper)

      const height = $content.clientHeight
      const animation = this.open ? openAnimation(height) : closeAnimation(height)
      const hasTallContent = height > 300

      $wrapper.animate(animation, {
        duration: oneUxDuration[hasTallContent ? 400 : 200] * 2
      })
    }
  }
})

const BaseClass = Open(Implicit(Focusable(Styled(OneUxElement))))

@customElement('one-ux-collapsible-section')
export class OneUxCollapsibleSectionElement extends BaseClass {
  @property({ type: String })
  public accessor heading = ''

  #expandedUsed = false
  @property({ type: Boolean, reflect: true })
  public set expanded(expanded: Optional<boolean>) {
    this.#expandedUsed = true
    this.open = !!expanded
  }
  public get expanded() {
    return this.open
  }

  @property({ type: Array })
  public accessor icons!: Icon[]

  @property({ attribute: 'disable-indent', type: Boolean, reflect: true })
  public accessor disableIndent = false

  @state()
  private accessor _hasContent = false

  constructor() {
    super()
    this.width = 'max'
  }

  protected willUpdate(): void {
    this.#logDeprecations()
  }

  protected render() {
    if (!this.heading?.length) {
      log.error('Missing heading, not rendering.')
      return
    }

    return html`<div class="one-ux-element--root">
      <div
        role="heading"
        aria-live="off"
        @click=${(event: MouseEvent) => {
          // `this.#refs.$button.contains(event.target)` doesn't match slotted child content of button
          // therefore we instead use `event.composedPath()`.
          const hasClickedOutsideButton = !event.composedPath().includes(this.#refs.$button.value!)
          if (hasClickedOutsideButton) {
            this.#handleUserToggle()
          }
        }}
      >
        <button ${ref(this.#refs.$button)} aria-expanded=${this.open} @click=${this.#handleUserToggle}>
          <div class="header-content">
            <slot name="header-start"></slot>
            <span
              ${ref(($ref?: Element) => $ref && this.#handleTooltip($ref as HTMLElement, this.heading))}
              class="title"
              >${this.heading}</span
            >
            ${this.#renderIcons()}
            <slot name="header-end"></slot>
          </div>
        </button>

        <slot name="indicator">
          <slot name="indicator-start"></slot>
          <one-ux-icon
            class=${classMap({
              indicator: true,
              expanded: this.open
            })}
            icon="toggle-down"
            size="200"
            aria-hidden="true"
          ></one-ux-icon>
          <slot name="indicator-end"></slot>
        </slot>
      </div>
      <div
        class=${classMap({
          'content-wrapper': true,
          expanded: this.open
        })}
      >
        <div
          class=${classMap({
            content: true,
            'has-content': this._hasContent
          })}
        >
          <slot @slotchange=${this.#onSlotchange}></slot>
        </div>
      </div>
    </div>`
  }

  #handleTooltip($el: HTMLElement, label: string) {
    requestAnimationFrame(() => {
      let remove = true
      if ($el) {
        const hasTruncatedText = $el.offsetWidth < $el.scrollWidth
        if (hasTruncatedText) {
          $el.parentElement?.setAttribute('one-ux-tooltip', label)
          $el.parentElement?.setAttribute('one-ux-tooltip-placement', 'below')
          remove = false
        }
      }
      if (remove) {
        $el.parentElement?.removeAttribute('one-ux-tooltip')
        $el.parentElement?.removeAttribute('one-ux-tooltip-placement')
      }
    })
  }

  #onSlotchange(e: any) {
    this._hasContent = (e.target?.assignedNodes() as HTMLSlotElement[]).length > 0
  }

  #renderIcons() {
    return this.icons
      ?.slice(0, 3)
      .map(
        (icon) =>
          html`<one-ux-icon
            class="icon"
            .set=${icon.set || 'default'}
            .icon=${icon.name as OneUxIconToken[keyof OneUxIconToken]}
            .label=${icon.text}
            style=${styleMap(icon.color ? { color: `var(--one-ux-palette--${icon.color})` } : {})}
            size="300"
          ></one-ux-icon>`
      )
  }

  #handleUserToggle() {
    this.open = !this.open
    this.dispatchEvent(new Event('toggle'))
  }

  #logDeprecations() {
    if (!this.hasUpdated) {
      if (this.icons) {
        log.deprecation('Usage of "icons" is deprecated in favor of "header-end" slot.')
      }
      if (this.#expandedUsed) {
        log.deprecation('Usage of "expanded" is deprecated in favor of "open".')
      }
    }
  }

  #refs = {
    $button: createRef<HTMLButtonElement>()
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'one-ux-collapsible-section': OneUxCollapsibleSectionElement
  }

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'one-ux-collapsible-section': OneUxCollapsibleSectionElement
    }
  }
}
