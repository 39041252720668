import { css } from 'lit'

export const style = css`
  .one-ux-element--root {
    --one-ux-pill--background: var(--one-ux-palette--rose-400);
    --one-ux-pill--font: var(--one-ux-font--mono-variant-400);
    --one-ux-pill--letter-spacing: var(--one-ux-letter-spacing--mono-variant-400);

    --one-ux-pill--size: 16px;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    border-radius: var(--one-ux-radius--max);
    color: var(--one-ux-palette--gray-100);
    padding: 0 var(--one-ux-spacing--200);
    text-align: center;

    background: var(--one-ux-pill--background);
    font: var(--one-ux-pill--font);
    letter-spacing: var(--one-ux-pill--letter-spacing);

    width: auto;
    height: var(--one-ux-pill--size);
    min-width: var(--one-ux-pill--size);
  }

  :host([weight='high']) .one-ux-element--root {
    --one-ux-pill--font: var(--one-ux-font--mono-variant-600);
    --one-ux-pill--letter-spacing: var(--one-ux-letter-spacing--mono-variant-600);
    --one-ux-pill--size: 20px;
  }

  :host([weight='low']) .one-ux-element--root {
    --one-ux-pill--font: var(--one-ux-font--mono-variant-200);
    --one-ux-pill--letter-spacing: var(--one-ux-letter-spacing--mono-variant-200);
    --one-ux-pill--size: 12px;
  }

  .one-ux-element--root:is(.is-single-digit, .is-empty) {
    width: var(--one-ux-pill--size);
    padding: 0;
  }

  .one-ux-element--root.is-empty {
    --one-ux-pill--size: 8px;

    &::before {
      content: '\\200b';
    }
  }

  :host([weight='high']) .one-ux-element--root.is-empty {
    --one-ux-pill--size: 12px;
  }

  :host([weight='low']) .one-ux-element--root.is-empty {
    --one-ux-pill--size: 6px;
  }
`
