import { css } from 'lit'

export const style = css`
  :host {
    /* Note that this is used in <one-ux-badge> too, change with caution. */
    --one-ux-icon-element--color: currentColor;
    --one-ux-icon-element--size: inherit;

    justify-content: center !important;
    align-items: center !important;
    vertical-align: -0.125em;
    font-size: var(--one-ux-icon-element--size);
  }

  svg {
    flex: 1 1 auto;
    min-width: 0;
    line-height: 0;
    height: 1em;
    fill: var(--one-ux-icon-element--color);
  }
`
