import { css } from 'lit'

export const style = css`
  :host {
    vertical-align: middle;
  }

  ::slotted(one-ux-icon) {
    font-size: var(--one-ux-size--icon-200) !important;
    width: var(--one-ux-size--icon-200) !important;
    height: var(--one-ux-size--icon-200) !important;
    overflow: hidden !important;
    flex-shrink: 0 !important;
  }

  slot[name='popout'] {
    --one-ux-element-internal--popout-constraint-max-height: 200px;
    --one-ux-element-internal--popout-constraint-width: 100%;
  }

  .adornment {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.is-empty {
      display: none;
    }
  }

  #button {
    --one-ux-button-element--background: var(--one-ux-palette--brand-neutral-100);
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-600);
    --one-ux-button-element--border-color: var(--one-ux-palette--brand-neutral-300);
    --one-ux-button-element--border-radius: var(--one-ux-radius--300);
    --one-ux-button-element--font: var(--one-ux-font--body-300);
    --one-ux-button-element--letter-spacing: var(--one-ux-letter-spacing--body-300);
    --one-ux-button-element--cursor: pointer;
    --one-ux-button-element--min-height: 32px;
    --one-ux-button-element--min-width: 74px;
    --one-ux-button-element--padding: 5px 14px;

    color: var(--one-ux-button-element--color);
    background-color: var(--one-ux-button-element--background);
    border-color: var(--one-ux-button-element--border-color);
    border-radius: var(--one-ux-button-element--border-radius);

    font: var(--one-ux-button-element--font);
    letter-spacing: var(--one-ux-button-element--letter-spacing);

    min-height: var(--one-ux-button-element--min-height);
    min-width: var(--one-ux-button-element--min-width);
    padding: var(--one-ux-button-element--padding);
    position: relative;

    cursor: var(--one-ux-button-element--cursor);

    border-style: solid;
    border-width: var(--one-ux-border-width--500);
    line-height: normal;

    box-sizing: border-box;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: var(--one-ux-spacing--small);

    transition-property: background-color, border-color, color;
    transition-duration: var(--one-ux-duration--100);
    transition-timing-function: var(--one-ux-easing--default);
  }

  one-ux-icon {
    font-size: var(--one-ux-size--icon-200);
  }

  .button-text {
    text-align: start;
  }

  #button.compact {
    height: 32px;
    min-width: 32px;
    --one-ux-button-element--padding: 0 !important;
    --one-ux-button-element--min-width: 0px;
  }

  :host([weight='low']) #button {
    --one-ux-button-element--min-height: 24px;
    --one-ux-button-element--padding: 1px 10px;
  }

  :host([weight='low']) #button.compact {
    height: 24px;
    min-width: 24px;
  }

  :host([weight='high']) #button {
    --one-ux-button-element--border-radius: var(--one-ux-radius--700);
    --one-ux-button-element--font: var(--one-ux-font--body-variant-300);
    --one-ux-button-element--letter-spacing: var(--one-ux-letter-spacing--body-variant-300);
  }

  #button:hover {
    --one-ux-button-element--background: var(--one-ux-palette--brand-neutral-200);
  }

  #button:is(:active, [aria-pressed='true']) {
    --one-ux-button-element--background: var(--one-ux-palette--brand-neutral-300);
    --one-ux-button-element--border-color: var(--one-ux-palette--brand-neutral-400);
  }

  #button:focus {
    outline: none;
  }

  :host([disabled]) #button {
    --one-ux-button-element--background: var(--one-ux-palette--gray-150) !important;
    --one-ux-button-element--color: var(--one-ux-palette--gray-400) !important;
    --one-ux-button-element--border-color: var(--one-ux-palette--gray-300) !important;
    --one-ux-button-element--cursor: default;
  }

  :host([purpose='main']) #button {
    --one-ux-button-element--background: var(--one-ux-palette--brand-core-400);
    --one-ux-button-element--color: var(--one-ux-palette--gray-100);
    --one-ux-button-element--border-color: var(--one-ux-palette--brand-core-400);
  }

  :host([purpose='main']) #button:hover {
    --one-ux-button-element--background: var(--one-ux-palette--brand-core-500);
    --one-ux-button-element--border-color: var(--one-ux-palette--brand-core-500);
  }

  :host([purpose='main']) #button:is(:active, [aria-pressed='true']) {
    --one-ux-button-element--background: var(--one-ux-palette--brand-core-600);
    --one-ux-button-element--border-color: var(--one-ux-palette--brand-core-600);
  }

  :host([purpose='caution']) #button {
    --one-ux-button-element--background: var(--one-ux-palette--red-300);
    --one-ux-button-element--color: var(--one-ux-palette--gray-100);
    --one-ux-button-element--border-color: var(--one-ux-palette--red-300);
  }

  :host([purpose='caution']) #button:hover {
    --one-ux-button-element--background: var(--one-ux-palette--red-500);
    --one-ux-button-element--border-color: var(--one-ux-palette--red-500);
  }

  :host([purpose='caution']) #button:is(:active, [aria-pressed='true']) {
    --one-ux-button-element--background: var(--one-ux-palette--red-600);
    --one-ux-button-element--border-color: var(--one-ux-palette--red-600);
  }

  :host([purpose='notice']) #button {
    --one-ux-button-element--background: var(--one-ux-palette--azure-200);
    --one-ux-button-element--border-color: var(--one-ux-palette--azure-400);
  }

  :host([purpose='notice']) #button:hover {
    --one-ux-button-element--background: var(--one-ux-palette--azure-300);
  }

  :host([purpose='notice']) #button:is(:active, [aria-pressed='true']) {
    --one-ux-button-element--background: var(--one-ux-palette--azure-400);
    --one-ux-button-element--border-color: var(--one-ux-palette--azure-500);
  }

  :host([implicit]) #button {
    --one-ux-button-element--background: transparent;
    --one-ux-button-element--border-color: transparent;
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-500);
  }

  :host([implicit]) #button:hover {
    --one-ux-button-element--background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.09);
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([implicit]) #button:is(:active, [aria-pressed='true']) {
    --one-ux-button-element--background: rgba(var(--one-ux-rgb--brand-neutral-600), 0.24);
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([implicit][disabled]) #button {
    --one-ux-button-element--background: none !important;
    --one-ux-button-element--border-color: transparent !important;
  }

  :host([implicit][purpose='main']) #button {
    --one-ux-button-element--background: none;
    --one-ux-button-element--border-color: transparent;
    --one-ux-button-element--color: var(--one-ux-palette--brand-core-400);
  }

  :host([implicit][purpose='main']) #button:hover {
    --one-ux-button-element--background: rgba(var(--one-ux-rgb--brand-core-400), 0.15);
    --one-ux-button-element--color: var(--one-ux-palette--brand-core-500);
  }

  :host([implicit][purpose='main']) #button:is(:active, [aria-pressed='true']) {
    --one-ux-button-element--background: rgba(var(--one-ux-rgb--brand-core-400), 0.33);
    --one-ux-button-element--color: var(--one-ux-palette--brand-core-600);
  }

  :host([implicit][purpose='caution']) #button {
    --one-ux-button-element--background: none;
    --one-ux-button-element--border-color: transparent;
    --one-ux-button-element--color: var(--one-ux-palette--red-500);
  }

  :host([implicit][purpose='caution']) #button:hover {
    --one-ux-button-element--background: rgba(var(--one-ux-rgb--red-400), 0.15);
    --one-ux-button-element--color: var(--one-ux-palette--red-500);
  }

  :host([implicit][purpose='caution']) #button:is(:active, [aria-pressed='true']) {
    --one-ux-button-element--background: rgba(var(--one-ux-rgb--red-400), 0.33);
    --one-ux-button-element--color: var(--one-ux-palette--red-600);
  }

  :host([implicit][purpose='notice']) #button {
    --one-ux-button-element--background: none;
    --one-ux-button-element--border-color: transparent;
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-500);
  }

  :host([implicit][purpose='notice']) #button:hover {
    --one-ux-button-element--background: rgba(var(--one-ux-rgb--azure-400), 0.21);
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([implicit][purpose='notice']) #button:is(:active, [aria-pressed='true']) {
    --one-ux-button-element--background: rgba(var(--one-ux-rgb--azure-400), 0.42);
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([state-keyboard-focus]) #button:focus,
  :host([state-keyboard-focus][purpose]) #button:focus {
    --one-ux-button-element--background: var(--one-ux-palette--keyboard-focus-200);
    --one-ux-button-element--border-color: var(--one-ux-palette--keyboard-focus-400);
    --one-ux-button-element--color: var(--one-ux-palette--brand-neutral-600);
  }

  :host([state-keyboard-focus]) #button:active,
  :host([state-keyboard-focus][purpose]) #button::active {
    --one-ux-button-element--background: var(--one-ux-palette--keyboard-focus-300);
  }

  .spinner-clip {
    position: absolute;
    inset: 0;
    pointer-events: none;
    border-radius: inherit;
    overflow: hidden;
  }

  .spinner {
    --spinner-color: var(--one-ux-palette--focus-400);
    --spinner-duration: 1s; /* TODO: Missing token for duration */
    --spinner-thickness: var(--one-ux-border-width--500);
    --spinner-easing: var(--one-ux-easing--default);

    position: absolute;

    :host(:is([purpose='main'], [purpose='caution']):not([implicit])) & {
      --spinner-color: var(--one-ux-palette--gray-100);
    }

    &:not(.round) {
      bottom: 0;

      height: var(--spinner-thickness);
      background: var(--spinner-color);
      animation:
        var(--spinner-duration) linear infinite spinner-opacity,
        calc(2 * var(--spinner-duration)) var(--spinner-easing) infinite spinner-flat;
    }

    &.round {
      top: 0;
      bottom: 0;
      right: 0;
      width: 50%;
      overflow: hidden;

      transform-origin: center left;
      animation:
        var(--spinner-duration) linear infinite spinner-opacity,
        var(--spinner-duration) var(--spinner-easing) infinite spinner-round-start;

      &:before {
        content: '';
        display: block;
        border-radius: var(--one-ux-radius--max);
        box-sizing: border-box;
        height: 100%;
        width: 200%;
        position: absolute;
        right: 0;

        border: var(--spinner-thickness) solid;
        border-color: var(--spinner-color) var(--spinner-color) transparent transparent;
        animation: var(--spinner-duration) var(--spinner-easing) infinite spinner-round-end;
      }
    }
  }

  @keyframes spinner-opacity {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes spinner-round-start {
    0% {
      transform: rotate(0deg);
    }
    16.7% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinner-round-end {
    0% {
      rotate: 0deg;
      transform: rotate(-135deg);
    }
    16.7% {
      rotate: 0deg;
    }
    83.3% {
      transform: rotate(225deg);
    }
    100% {
      rotate: -360deg;
      transform: rotate(225deg);
    }
  }

  @keyframes spinner-flat {
    0% {
      left: 0;
      right: 100%;
    }
    8.3% {
      left: 0;
    }
    41.7% {
      right: 0;
    }
    50% {
      left: 100%;
      right: 0;
    }
    58.3% {
      right: 0;
    }
    91.7% {
      left: 0;
    }
    100% {
      left: 0;
      right: 100%;
    }
  }
`
