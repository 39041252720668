import { html, nothing, TemplateResult } from 'lit'
import { classMap } from 'lit/directives/class-map.js'
import { ifDefined } from 'lit/directives/if-defined.js'
import { ref } from 'lit/directives/ref.js'
import type { LanguageSet } from '../language.js'
import { setOverflow } from '../utils/setOverflow.js'
import type { InternalTabBase, InternalTab, ScrollDirection } from '../types.js'
import type { OneUxSpacingToken } from '../../../generated/design-tokens.js'
import { styleMap } from 'lit/directives/style-map.js'
import type { weight } from '../../../mixins/Weight.js'

export type TabsFragmentOptions = {
  tabs: InternalTabBase[]
  indent: OneUxSpacingToken
  focusedTab: InternalTabBase
  label: string
  iconSize: string
  languageSet: LanguageSet
  weight: weight
  onTabClicked: (tab: InternalTabBase, activate: boolean) => void
  onTabScroll: (e: Event, direction: ScrollDirection) => void
}

export function TabsFragment(options: TabsFragmentOptions): TemplateResult {
  const indentation = !options.indent || options.indent === 'none' ? null : `var(--one-ux-spacing--${options.indent})`
  return html`<div
    class=${classMap({
      tabs: true,
      'is-subtabs': options.tabs[0].type === 'sub',
      'has-subtabs': options.tabs.some((tab) => hasSubtabs(tab))
    })}
  >
    <div class="tabs--navigation">
      <one-ux-widget-button
        icon="toggle-left"
        implicit
        class="tabs--navigation-button left"
        @click=${(e: Event) => options.onTabScroll(e, 'left')}
      ></one-ux-widget-button>
      <div
        class="tabs--list"
        role="tablist"
        style=${styleMap({
          marginLeft: indentation,
          marginRight: indentation
        })}
        aria-roledescription=${ifDefined(getRoleDescriptionForTablist(options.tabs, options.languageSet))}
        aria-label=${options.label}
        @scroll=${(e: Event) => setOverflow((e.target as Element).closest('.tabs') as HTMLElement)}
      >
        ${options.tabs.map((tab) => {
          return html`<div
            id=${ifDefined(tab === options.focusedTab ? 'active-tab-item' : undefined)}
            aria-selected=${tab.isActive}
            aria-disabled=${ifDefined(tab.disabled || undefined)}
            pdr-test-hook="one-ux-tabs-tab-${tab.id}"
            @click=${(e: Event) => {
              options.onTabClicked(tab, true)
              options.onTabScroll(e, 'current')
            }}
            class=${classMap({
              'tabs--button': true,
              'is-only-icon': tab.isIconOnly
            })}
            role="tab"
            aria-roledescription=${ifDefined(getRoleDescriptionForTab(tab, options.languageSet))}
            aria-label=${tab.label}
            aria-controls="tabs--tabpanel"
          >
            <span
              ${ref(($ref?: Element) => $ref && handleTooltip($ref as HTMLElement, tab.isIconOnly, tab.label))}
              class="tabs--button-text"
            >
              ${tab.icon
                ? html`<one-ux-icon
                    set=${ifDefined(tab.icon.set)}
                    icon=${tab.icon.name}
                    size=${options.iconSize}
                    aria-hidden="true"
                  ></one-ux-icon>`
                : nothing}
              ${tab.text}
              ${tab.pill
                ? html`
                    <one-ux-pill
                      number=${ifDefined(tab.pill.value)}
                      class=${classMap({
                        'is-empty': tab.pill.value == null
                      })}
                      weight=${options.weight}
                    ></one-ux-pill>
                  `
                : nothing}
            </span>
          </div>`
        })}
      </div>
      <one-ux-widget-button
        icon="toggle-right"
        implicit
        class="tabs--navigation-button right"
        @click=${(e: Event) => options.onTabScroll(e, 'right')}
      ></one-ux-widget-button>
    </div>
  </div>`
}

function handleTooltip($el: HTMLElement, isIconOnly: boolean, label: string) {
  requestAnimationFrame(() => {
    let remove = true
    if ($el) {
      const hasTruncatedText = $el.offsetWidth < $el.scrollWidth
      const hasLargerTextThanParent = $el.offsetWidth >= $el.closest('.tabs--list')!.clientWidth
      if (isIconOnly || hasTruncatedText || hasLargerTextThanParent) {
        $el.parentElement?.setAttribute('one-ux-tooltip', label)
        $el.parentElement?.setAttribute('one-ux-tooltip-placement', 'below')
        remove = false
      }
    }
    if (remove) {
      $el.parentElement?.removeAttribute('one-ux-tooltip')
      $el.parentElement?.removeAttribute('one-ux-tooltip-placement')
    }
  })
}

const hasSubtabs = (tab: InternalTabBase) => tab.type === 'parent' && !!(tab as InternalTab).subtabs.length

const getRoleDescriptionForTablist = (tabs: InternalTabBase[], languageSet: LanguageSet) => {
  if (tabs.some(hasSubtabs)) {
    return languageSet.tabsLevelOne
  } else if (tabs[0].type === 'sub') {
    return languageSet.tabsLevelTwo
  }

  return undefined
}

const getRoleDescriptionForTab = (tab: InternalTabBase, languageSet: LanguageSet) => {
  if (hasSubtabs(tab)) {
    return languageSet.tabIncludingSubtabs
  } else if (tab.type === 'sub') {
    return languageSet.subtab
  }

  return undefined
}
