import { property } from 'lit/decorators.js'
import { OneUxElement } from '../OneUxElement.js'
import { Constructor } from '../utils.js'

type purpose =
  /* What is the intention of the component? */
  | 'default' // No special meaning, just plain default component
  | 'caution' // User should be thoughtful about their actions and information presented
  | 'main' // Is intended to be part of the main flow of the user interactions and attention
  | 'notice' // Should at least be noticed by the user, but it is not the main focus

export declare class IPurpose {
  purpose: purpose
}

export const Purpose = <TSuperClass extends Constructor<OneUxElement>>(SuperClass: TSuperClass) => {
  class PurposeClass extends SuperClass {
    /**
     * Describes the intention of the component.
     */
    @property({ type: String, reflect: true })
    public accessor purpose: purpose = 'default'
  }
  return PurposeClass as Constructor<IPurpose> & TSuperClass
}
